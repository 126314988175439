import "../../styles/Citas.css";
import * as Constants from "../../utils/Constants";

import CitasTable from "./CitasTable";
import { getAllDocumentTypes, getMedicalAppointmentData, postMedicalAppointmentMessage } from "../../utils/CitasServices";
// import { notify, notifyError } from "../../components/Notify";

import { Button, CircularProgress, Dialog, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

const searchFieldsForm = {
  documentNumber: "",
  documentType: ""
}

export default function Citas() {
  const [formSearchFields, setFormSearchFields] = useState(searchFieldsForm);
  const [listDocument, setListDocument] = useState([]);
  const [listMedicalAppointment, setListMedicalAppointment] = useState([]);
	const [openLoading, setOpenLoading] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [selectionModel, setSelectionModel] = useState([]);

  try {

    useEffect(() => {
      if (listDocument.length === 0) searchAllDocumentTypes();
    }, []);

    const searchAllDocumentTypes = async () => {
      setOpenLoading(true);
      setListDocument([]);
      try {
        const response = await getAllDocumentTypes();
        if (response !== undefined && response.msm !== undefined) {
          if (response.msm === Constants.SERVICE_MESSAGE_SUCCESS) setListDocument(response.Respuesta)
          else console.log(Constants.ALERT_INFO_EMPTY_DATA); // notify(Constants.ALERT_INFO_EMPTY_DATA);
        } else {
          console.log(Constants.ALERT_ERROR);
          // notifyError(Constants.ALERT_ERROR);
        }
      } catch (error) {
        console.log(Constants.ALERT_ERROR);
        // notifyError(Constants.ALERT_ERROR);
      }
      setOpenLoading(false);
    }

    const searchPatientData = async () => {
      setOpenLoading(true);
      setListMedicalAppointment([]);
      try {
        if (formSearchFields.documentNumber !== "" && formSearchFields.documentType !== "") {
          const response = await getMedicalAppointmentData(formSearchFields);
          if (response !== undefined && response.message !== undefined) {
            if (response.message === Constants.SERVICE_MESSAGE_SUCCESS_2 && response.status) setListMedicalAppointment(response.object)
            else console.log(Constants.ALERT_INFO_EMPTY_DATA); // notify(Constants.ALERT_INFO_EMPTY_DATA);
          } else {
            console.log(Constants.ALERT_ERROR);
            // notifyError(Constants.ALERT_ERROR);
          }
        } else {
          console.log(Constants.ALERT_INFO_EMPTY_PARAMS); // notify(Constants.ALERT_INFO_EMPTY_PARAMS);
        }
      } catch (error) {
        console.log(Constants.ALERT_ERROR);
        // notifyError(Constants.ALERT_ERROR);
      }
      setOpenLoading(false);
    }

    const sendMessage = async (type) => {
      setOpenLoading(true);
      try {
        const body = {
          autorizacionNumero: selectionModel,
          tipoMensaje: type
        }
        const response = await postMedicalAppointmentMessage(body);
        if (response !== undefined && response.message !== undefined) {
          if (response.message === Constants.SERVICE_MESSAGE_SUCCESS_2) console.log(response);
          else console.log(response); // notify(Constants.ALERT_INFO_EMPTY_DATA);
        } else {
          console.log(Constants.ALERT_ERROR);
          // notifyError(Constants.ALERT_ERROR);
        }
      } catch (error) {
        console.log(Constants.ALERT_ERROR);
        // notifyError(Constants.ALERT_ERROR);
      }
      setOpenLoading(false);
    }

    return (
      <div className="container">
        <div className="searchContainer">
          <Grid container spacing={3}>
            <Grid item xs={3}>
              <FormControl variant="filled" className="searchFields">
                <InputLabel>{Constants.LABEL_DOCUMENT_TYPE}</InputLabel>
                <Select
                  onChange={(e) => {
                    setFormSearchFields((last) => {
                      return { ...last, documentType: e.target.value };
                    });
                  }}
                  size="medium"
                  value={formSearchFields.documentType}
                >
                  <MenuItem value={""}></MenuItem>
                  { listDocument.map((document) => {
                    return (
                      <MenuItem value={document.id}>{document.descripcion}</MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <TextField
                className="searchFields"
                InputLabelProps={{ shrink: true, }}
                label={Constants.LABEL_DOCUMENT_NUMBER}
                onChange={(e) => {
                  setFormSearchFields((last) => {
                    return { ...last, documentNumber: e.target.value };
                  });
                }}
                type="text"
                value={formSearchFields.documentNumber}
                variant="filled"
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                className="searchButton"
                color="primary"
                onClick={searchPatientData}
                size="large"
                variant="contained"
              >
                {Constants.LABEL_BUTTON_SEARCH}
              </Button>
            </Grid>
          </Grid>
        </div>
        { listMedicalAppointment.length > 0 &&
          <>
            <div className="tableContainer">
              <CitasTable
                data={listMedicalAppointment}
                pageSize={pageSize}
                selectionModel={selectionModel}
                setPageSize={setPageSize}
                setSelectionModel={setSelectionModel}
              />
            </div>
            <div className="searchContainer">
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    disabled={!selectionModel.length}
                    onClick={() => sendMessage("0")}
                    size="large"
                    variant="contained"
                  >
                    {Constants.LABEL_BUTTON_SEND_INFO}
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    color="primary"
                    disabled={!selectionModel.length}
                    onClick={() => sendMessage("1")}
                    size="large"
                    variant="contained"
                  >
                    {Constants.LABEL_BUTTON_SEND_INSTRUCTIONS}
                  </Button>
                </Grid>
              </Grid>
            </div>
          </>
        }
        <Dialog
          open={openLoading}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              width: 100,
              height: 100,
            },
          }}
        >
          <CircularProgress color="inherit" />
        </Dialog>
      </div>
    );
  } catch (error) {
    return(
      <>{error}</>
    );
  }
}
