import "../styles/Navbar.css";
import { DataGrid } from "@material-ui/data-grid";

export default function pruebas() {
	const columns = [{ field: "customerId", headerName: "id", width: 100 }];
	const rows = [
		{ id: 1, date: "2020-01-05", customerId: "11091700", amount: 3 },
		{ id: 2, date: "2020-01-02", customerId: "Anonymous", amount: 1 },
	];
	return (
		<div>
			<DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
		</div>
	);
}
