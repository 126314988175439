import { useEffect, useState } from "react";
import {
    consulta_tipo_documento,
    crear_prefactura,
    consulta_cups,
    consulta_paciente,
    listar_prefactura
} from "../../utils/fecth";
import {
    Button,
    TextField,
    Typography,
    Box,
    Modal,
    Backdrop,
    Fade,
    CircularProgress,
    Dialog,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Grid,
    Snackbar
} from "@material-ui/core";
import { Alert, Autocomplete } from '@mui/material';
import * as Constants from "../../utils/Constants";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    cubrePaciente: "NO",
};

export const ModalPrefactura = (props) => {
    //export const ModalPrefactura = (open, handleClose, tipoDocumento, listaCups) => {
    const { open, handleClose, setPrefacturas } = props;
    const dataInicial = {
        tipoDocumento: "",
        numeroDocumento: "",
        cups: "",
        cantidad: 1,
        valor: 95000,
        fecha: "",
        cubrePaciente: 0
    };
    const initial = {
        codigo: "",
        descripcion: "",
    };
    const [tipoDocEPS, setTipoDocEPS] = useState([]);
    const [data, setData] = useState(dataInicial);
    const [openLoading, setOpenLoading] = useState(false);
    const [cup, setCup] = useState(initial);
    const [cups, setCups] = useState({});
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [severity, setSeverity] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const formattedValor = data.valor.toLocaleString("es-CO", {
        style: "currency",
        currency: "COP"
      });
    const loadDocument = async () => {
        setOpenLoading(true);
        const tipoDoc = await consulta_tipo_documento();
        const tipoDocEPS = tipoDoc.filter((item) => item.numeroNIT === 900156264);
        setTipoDocEPS(tipoDocEPS);
        const [cups] = await Promise.all([consulta_cups()]);
        setCups(cups);
        setOpenLoading(false);
    };

    useEffect(() => {
        loadDocument();
    }, []);

    const handleDateChange = (event) => {
        setData({
            ...data,
            fecha: event.target.value,
        });
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setData((prevData) => ({
            ...prevData,
            [name]: value,
            cups: cup?.codigo || "",
        }));
    };

    const handleCrear = async () => {
        setOpenLoading(true);
        const isDataValid = Object.values(data).every((value) => value !== "");
        try {
            if (isDataValid) {
                const body = {
                    tipoDocumento: data?.tipoDocumento,
                    numeroDocumento: data?.numeroDocumento,
                    cups: data?.cups,
                    cantidad: data?.cantidad,
                    valor: data?.valor.replace(/ /g,"").replace("$ ","").replace(",00","").replace(".",""),
                    fecha: data?.fecha,
                    detalleCups: cup?.descripcion,
                    cubrePaciente: data?.cubrePaciente
                }
                const cedula = await consulta_paciente(data);
                if (cedula.length !== 0) {
                    await crear_prefactura(body);
                    const prefacturas = await listar_prefactura();
                    setPrefacturas(prefacturas);
                    handleClose();
                } else {
                    setAlertMessage(Constants.LABEL_ALERT_DOCUMENT_NOT_FOUND);
                    setSeverity('warning');
                    setOpenSnackbar(true);
                }
            } else {
                setAlertMessage(Constants.LABEL_ALERT_FIELD_EMPTY);
                setSeverity('warning');
                setOpenSnackbar(true);
            }
        } catch (error) {
            setAlertMessage(Constants.LABEL_ALERT_FIELD_EMPTY);
        }
        setOpenLoading(false);
    };

    return (
        <>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={3000}
                onClose={() => setOpenSnackbar(false)}
            >
                <Alert severity={severity}>
                    {alertMessage}
                </Alert>
            </Snackbar>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}>
                <Fade in={open}>
                    <Box sx={style}>
                        <Typography variant="h6" component="h2" align="center">
                            {Constants.LABEL_PREFACTURE}
                        </Typography>
                        <br />
                        <Grid container spacing={2}>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="numeroDocumento"
                                    label={Constants.LABEL_DOCUMENT_NUMBER}
                                    name="numeroDocumento"
                                    onChange={handleInputChange}
                                    type="text"
                                    value={data.numeroDocumento}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{Constants.LABEL_DOCUMENT_TYPE}</InputLabel>
                                    <Select name="tipoDocumento" value={data.tipoDocumento} onChange={handleInputChange} >
                                        {tipoDocEPS.map((doc) => (
                                            <MenuItem value={doc.id}>{doc.nombre}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Autocomplete
                                    id="codigoCUPS"
                                    options={cups}
                                    className="autocomplete"
                                    getOptionLabel={(option) => option?.codigo}
                                    renderInput={(params) => <TextField {...params} label={Constants.LABEL_CUPS} />}
                                    value={cup}
                                    onChange={(event, newValue) => {
                                        setCup(newValue);
                                        setData((prevData) => ({
                                            ...prevData,
                                            cups: newValue?.codigo || "",
                                        }));
                                    }}
                                    sx={{ width: 200 }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <Autocomplete
                                    id="descripcionCups"
                                    options={cups}
                                    className="autocompleteDos"
                                    getOptionLabel={(option) => option?.descripcion}
                                    renderInput={(params) => <TextField {...params} label={Constants.LABEL_DESCRIPTION} />}
                                    value={cup}
                                    onChange={(event, newValue) => { 
                                        setCup(newValue); 
                                        setData({ 
                                            ...data, cups: newValue 
                                        }); 
                                    }}
                                    sx={{ width: 360 }}
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="cantidad"
                                    label={Constants.LABEL_QUANTITY}
                                    name="cantidad"
                                    onChange={handleInputChange}
                                    type="text"
                                    value={data.cantidad}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    className="valor"
                                    label={Constants.LABEL_VALUE}
                                    name="valor"
                                    onChange={handleInputChange}
                                    type="text"
                                    value={formattedValor}
                                    required
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <TextField
                                    label="Fecha"
                                    type="date"
                                    value={data.fecha}
                                    onChange={handleDateChange}
                                    focused
                                />
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <FormControl fullWidth>
                                    <InputLabel>{Constants.LABEL_PATIENT}</InputLabel>
                                    <Select name="cubrePaciente" value={data.cubrePaciente} onChange={handleInputChange} >
                                        <MenuItem value={0}>{Constants.LABEL_NO}</MenuItem>
                                        <MenuItem value={1}>{Constants.LABEL_YES}</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item md={12}></Grid>
                            <Grid item xs={6} md={6}>
                                <center>
                                    <Button variant="contained" onClick={handleClose} color="secondary">
                                        {Constants.LABEL_BUTTON_CANCEL}
                                    </Button>
                                </center>
                            </Grid>
                            <Grid item xs={6} md={6}>
                                <center>
                                    <Button variant="contained" onClick={handleCrear} color="primary">
                                        {Constants.LABEL_BUTTON_CREATE}
                                    </Button>
                                </center>
                            </Grid>
                        </Grid>
                    </Box>
                </Fade>
            </Modal>
            <Dialog
                open={openLoading}
                PaperProps={{
                    style: {
                        backgroundColor: "transparent",
                        boxShadow: "none",
                        width: 100,
                        height: 100,
                    },
                }}
            >
                <CircularProgress color="inherit" />
            </Dialog>
        </>
    )
};