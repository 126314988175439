import { useState, useEffect } from "react";
// import { useParams } from "react-router-dom";
import { TextField, CircularProgress, Dialog, Button } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
// import { today } from "../utils/funciones";

import "../styles/MIPRES.css";
import {
	consultar_cie10,
	consultar_tipo_documento,
	consultar_ambito_atencion,
	consultar_tipo_medicamento,
	consultar_tipo_dci,
	consultar_concentracion,
	consultar_campos,
	consultar_indicaciones,
	consultar_tipo_frecuencia,
	insertar_mipres,
} from "../utils/fecth";

export default function Mipres() {
	const initial = {
		id: "",
		codigo: "",
		descripcion: "",
	};
	const initialForm = {
		admision: "",
		tipo_documento: initial,
		numero_documento: "",
		ambito_atencion: initial,
		contrareferencia: "",
		diagnostico1: initial,
		diagnostico2: initial,
		diagnostico3: initial,
		tipo_medicamento: initial,
		dci: initial,
		concentraciones: initial,
		forma_farmaceutica: initial,
		via_administracion: initial,
		justificacion: "",
		numero_dosis: "",
		medida_dosis: initial,
		frecuencia: "",
		unidad_frecuencia: initial,
		indicaciones_especiales: initial,
		duracion: "",
		periodo: initial,
		total: "",
		unidad_total: initial,
		recomendaciones: "",
	};

	const [openLoading, setOpenLoading] = useState(false);
	const [cie10t, setCie10t] = useState({});
	const [tipoDoct, setTipoDoct] = useState({});
	const [ambitoAtenciont, setAmbitoAtenciont] = useState({});
	const [tipoMedicamentot, setTipoMedicamentot] = useState({});
	const [tipoDcit, setTipoDcit] = useState({});
	const [concentracionest, setConcentracionest] = useState(initial);
	const [campost, setCampost] = useState(initial);
	const [indicacionest, setIndicacionest] = useState(initial);
	const [frecuenciat, setFrecuenciat] = useState(initial);
	const [form, setForm] = useState(initialForm);

	// console.log(form);
	const loadDocument = async () => {
		setOpenLoading(true);
		const [cie10, tipoDoc, ambitoAtencion, tipoMedicamento, tipoDci, indicaciones, frecuencia] =
			await Promise.all([
				consultar_cie10(),
				consultar_tipo_documento(),
				consultar_ambito_atencion(),
				consultar_tipo_medicamento(),
				consultar_tipo_dci(),
				consultar_indicaciones(),
				consultar_tipo_frecuencia(),
			]);
		setCie10t(cie10);
		setTipoDoct(tipoDoc);
		setAmbitoAtenciont(ambitoAtencion);
		setTipoMedicamentot(tipoMedicamento);
		setTipoDcit(tipoDci);
		setIndicacionest(indicaciones);
		setFrecuenciat(frecuencia);
		setOpenLoading(false);
	};

	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleText = (event) => {
		setForm({
			...form,
			[event.target.name]: event.target.value,
		});
	};

	const handleAutocomple = async (name, newValue) => {
		setOpenLoading(true);
		if (name === "dci") {
			const concentracion = await consultar_concentracion(newValue);
			setConcentracionest(concentracion);
		}
		if (name === "concentraciones") {
			const campos = await consultar_campos(newValue);
			setCampost(campos);
		}
		setForm({
			...form,
			[name]: newValue,
		});
		setOpenLoading(false);
	};
	const handleGuardar = async () => {
		setOpenLoading(true);
		await insertar_mipres(form);
		setOpenLoading(false);
	};
	return (
		<div className="contact">
			<div className="leftSide">
				<label> MIPRES</label>

				<TextField
					label="Numero Admision"
					type="text"
					className="numAd"
					name="admision"
					value={form.admision}
					onChange={(event) => {
						handleText(event);
					}}
				/>

				<form className="formIZ">
					<div>
						<Autocomplete
							className="autocompleteUbicacion"
							options={tipoDoct}
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Tipo documento" />}
							value={form.tipo_documento}
							onChange={(event, newValue) => handleAutocomple("tipo_documento", newValue)}
						/>
						<TextField
							label="Numero documento"
							type="text"
							// className="numAd"
							name="numero_documento"
							value={form.numero_documento}
							onChange={(event) => {
								handleText(event);
							}}
						/>
					</div>
					<div>
						<Autocomplete
							className="autocompleteUbicacion"
							options={ambitoAtenciont}
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Ambito de atencion" />}
							value={form.ambito_atencion}
							onChange={(event, newValue) => handleAutocomple("ambito_atencion", newValue)}
						/>
						<Autocomplete
							className="autocompleteSolicitud"
							options={tipoMedicamentot}
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Tipo medicamento" />}
							value={form.tipo_medicamento}
							onChange={(event, newValue) => handleAutocomple("tipo_medicamento", newValue)}
						/>
					</div>

					<div>
						<Autocomplete
							id="codigoCies"
							options={cie10t}
							className="autocomplete"
							getOptionLabel={(option) => option?.codigo}
							renderInput={(params) => <TextField {...params} label="CIE10" />}
							value={form.diagnostico1}
							onChange={(event, newValue) => handleAutocomple("diagnostico1", newValue)}
						/>
						<Autocomplete
							id="descripcionCies"
							options={cie10t}
							className="autocompleteDos"
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Descripcion" />}
							value={form.diagnostico1}
							onChange={(event, newValue) => handleAutocomple("diagnostico1", newValue)}
						/>
					</div>
					<div>
						<Autocomplete
							className="autocompleteUbicacion"
							options={tipoDcit}
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="DCI" />}
							onChange={(event, newValue) => handleAutocomple("dci", newValue)}
						/>
						<Autocomplete
							className="autocompleteSolicitud"
							options={concentracionest}
							getOptionLabel={(option) => option?.codigo}
							renderInput={(params) => <TextField {...params} label="Concentracion" />}
							onChange={(event, newValue) => handleAutocomple("concentraciones", newValue)}
						/>
					</div>
					<div>
						<Autocomplete
							className="autocompleteUbicacion"
							options={campost}
							getOptionLabel={(option) => option?.descripcionff}
							renderInput={(params) => <TextField {...params} label="Forma farmaceutica" />}
							onChange={(event, newValue) => handleAutocomple("forma_farmaceutica", newValue.idff)}
						/>
						<Autocomplete
							className="autocompleteSolicitud"
							options={campost}
							getOptionLabel={(option) => option?.descripciontva}
							renderInput={(params) => <TextField {...params} label="Via administracion" />}
							onChange={(event, newValue) => handleAutocomple("via_administracion", newValue.idtva)}
						/>
					</div>
					<TextField
						label="Justificacion"
						type="text"
						name="justificacion"
						value={form.justificacion}
						onChange={(event) => {
							handleText(event);
						}}
					/>
				</form>
			</div>

			<div className="rightSide">
				<form id="contact-form" method="POST">
					<Autocomplete
						className="autocompleteUbicacion"
						options={indicacionest}
						getOptionLabel={(option) => option?.descripcion}
						renderInput={(params) => <TextField {...params} label="Indicaciones especiales" />}
						onChange={(event, newValue) => handleAutocomple("indicaciones_especiales", newValue)}
					/>
					<div>
						<TextField
							label="Numero dosis"
							type="number"
							name="numero_dosis"
							value={form.numero_dosis}
							onChange={(event) => {
								handleText(event);
							}}
						/>
						<Autocomplete
							className="autocompleteUbicacion"
							options={campost}
							getOptionLabel={(option) => option?.descripciontmd}
							renderInput={(params) => <TextField {...params} label="Medida dosis" />}
							onChange={(event, newValue) => handleAutocomple("medida_dosis", newValue.idtmd)}
						/>
					</div>

					<div>
						<TextField
							label="Frecuencia"
							type="number"
							name="frecuencia"
							value={form.frecuencia}
							onChange={(event) => {
								handleText(event);
							}}
						/>
						<Autocomplete
							className="autocompleteUbicacion"
							options={frecuenciat}
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Unidad frecuencia" />}
							onChange={(event, newValue) => handleAutocomple("unidad_frecuencia", newValue)}
						/>
					</div>

					<div>
						<TextField
							label="Duracion"
							type="number"
							name="duracion"
							value={form.duracion}
							onChange={(event) => {
								handleText(event);
							}}
						/>
						<Autocomplete
							className="autocompleteUbicacion"
							options={frecuenciat}
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Periodo " />}
							onChange={(event, newValue) => handleAutocomple("periodo", newValue)}
						/>
					</div>

					<div>
						<TextField
							label="Total"
							type="number"
							name="total"
							value={form.total}
							onChange={(event) => {
								handleText(event);
							}}
						/>
						<Autocomplete
							className="autocompleteUbicacion"
							options={campost}
							getOptionLabel={(option) => option?.descripciontut}
							renderInput={(params) => <TextField {...params} label="Unidad farmaceutica" />}
							onChange={(event, newValue) => handleAutocomple("unidad_total", newValue.idtut)}
						/>
					</div>
					<TextField
						label="Recomendaciones"
						type="text"
						name="recomendaciones"
						value={form.recomendaciones}
						onChange={(event) => {
							handleText(event);
						}}
					/>
					<div className="buttons">
						<Button variant="contained" onClick={() => handleGuardar()}>
							Guardar Orden
						</Button>
					</div>
				</form>
			</div>

			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
}
