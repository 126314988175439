
/** CITAS **/ 

// Labels
export const LABEL_ALERT_DOCUMENT_NOT_FOUND = "El tipo y número de documentos ingresados no cuentan con admisión";
export const LABEL_ALERT_FIELD_EMPTY = "Por favor, completa todos los campos";
export const LABEL_AUTHORIZATION_NUMBER = "Autorizaci\u00f3n";
export const LABEL_AUTHORIZATION_TYPE = "Tipo autorización";
export const LABEL_BUTTON_SEARCH = "Buscar";
export const LABEL_BUTTON_SEND_INFO = "Enviar Informaci\u00f3n de la cita";
export const LABEL_BUTTON_SEND_INSTRUCTIONS = "Enviar Instrucciones de preparaci\u00f3n";
export const LABEL_BUTTON_CANCEL = "Cancelar";
export const LABEL_BUTTON_CREATE = "Crear";
export const LABEL_BUTTON_CREATE_PREFACTURE = "Crear prefactura";
export const LABEL_CUPS = "CUPS";
export const LABEL_DATE = "Fecha";
export const LABEL_DESCRIPTION = "Descripci\u00f3n";
export const LABEL_DOCUMENT_NUMBER = "N\u00famero de documento";
export const LABEL_DOCUMENT_NUMBER_SHORT = "Documento";
export const LABEL_DOCUMENT_TYPE = "Tipo de documento";
export const LABEL_MEDICAL_APPOINTMENT = "Citas";
export const LABEL_MEDICAL_APPOINTMENT_DATE = "Fecha y hora";
export const LABEL_MEDICAL_APPOINTMENT_VALUE = "Valor";
export const LABEL_MEDICAL_PLACE_ADDRESS = "Direcci\u00f3n";
export const LABEL_MEDICAL_PLACE_NAME = "Consultorio";
export const LABEL_PAYER = "Pagador";
export const LABEL_PATIENT_NAME = "Paciente";
export const LABEL_PATIENT_PHONE = "Celular";
export const LABEL_PREFACTURE = "Pre-factura";
export const LABEL_PREFACTURE_NUMBER = "Prefactura";
export const LABEL_QUANTITY = "Cantidad";
export const LABEL_VALUE = "Valor";
export const LABEL_YES = "SI";
export const LABEL_NO = "NO";
export const LABEL_PATIENT = "¿Cubre el paciente?";
export const LABEL_COVER = "Cubre Paciente";

// Enrutamiento
export const ROUTE_MEDICAL_APPOINTMENT  = "/citas";
export const ROUTE_PREFACTURE = "/prefactura";

// Mensajes de validación
export const ALERT_INFO_EMPTY_PARAMS = "Debe llenar los campos";
export const ALERT_ERROR = "Hubo un error, vuelva a intentar";
export const ALERT_INFO_EMPTY_DATA = "No se encontraro registros";

// Mensajes Servicios
export const SERVICE_MESSAGE_SUCCESS = "SERVICE_SUCCESS";
export const SERVICE_MESSAGE_SUCCESS_2 = "Éxito";

// Servicios Paths
export const PATH_DOCUMENT_TYPES = "consultar_tipo_documento";
export const PATH_MEDICAL_APPOINTMENT_LIST = "cita/listar";
export const PATH_MEDICAL_APPOINTMENT_MSN = "cita/mensajeria";
export const PATH_MEDICAL_APPOINTMENT_URL = "cita/generar-url";

// Tags
export const TAG_AUTHORIZATION_NUMBER = "autorizacion";
export const TAG_DOCUMENT_NUMBER = "pacienteNumeroDocumento";
export const TAG_DOCUMENT_TYPE = "pacienteTipoDocumento";
export const TAG_MEDICAL_APPOINTMENT = "Citas";
export const TAG_MEDICAL_APPOINTMENT_DATE = "fechaCita";
export const TAG_MEDICAL_APPOINTMENT_VALUE = "valor";
export const TAG_MEDICAL_PLACE_ADDRESS = "consultorioDireccion";
export const TAG_MEDICAL_PLACE_NAME = "consultorioNombre";
export const TAG_PATIENT_NAME = "pacienteNombre";
export const TAG_PATIENT_PHONE = "pacienteCelular";
export const TAG_CUPS = "cups";
export const TAG_QUANTITY = "cantidad";
export const TAG_VALUE = "valor";
export const TAG_DESCRIPTION = "descripcionCup";
export const TAG_PATIENT = "cubrePaciente";
export const TAG_DATE = "fecha";
export const TAG_PREFACTURE = "pkPrefactura";
export const TAG_EPS = "eps";