import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./pages/Home";
import Pacientes from "./pages/Pacientes";
import Estancias from "./pages/EstanciaHospitalaria";
import Ordenes from "./pages/OrdenesMedicas";
import Mipres from "./pages/MIPRES";
import pruebas from "./pages/pruebas";
import tablaMipres from "./pages/tablaMipres";
import Ambulatorio from "./pages/Ambulatorio";
import Citas from "./pages/Citas/Citas";
import Prefactura from "./pages/Prefactura/Prefactura";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import * as Constants from "./utils/Constants";


export default function App() {
	function NotFound() {
		return <>Ha llegado a una página que no existe</>;
	}
	return (
		<div className="App">
			<Router>
				<Navbar />
				<Switch>
					<Route path="/" exact component={Home} />
					<Route path="/pacientes" exact component={Pacientes} />
					<Route path="/ordenes-medicas" exact component={Ordenes} />
					<Route path="/ordenes-medicas/:nombreParametro" exact component={Ordenes} />
					<Route path="/estancias-hospitalarias" exact component={Estancias} />
					<Route path="/estancias-hospitalarias/:nombreParametro" exact component={Estancias} />
					<Route path="/MIPRES" exact component={Mipres} />
					<Route path="/tablaMipres" exact component={tablaMipres} />
					<Route path="/ambulatorio" exact component={Ambulatorio} />
					<Route path="/pruebas" exact component={pruebas} />
					<Route path={Constants.ROUTE_MEDICAL_APPOINTMENT} exact component={Citas} />
					<Route path={Constants.ROUTE_PREFACTURE} exact component={Prefactura}/>
					<Route path="*" component={NotFound} />
				</Switch>
				<Footer />
			</Router>
		</div>
	);
}
