import * as Constants from "../../utils/Constants";

import { Grid } from "@material-ui/core";
import { DataGrid, esES, GridToolbar } from "@mui/x-data-grid";

export default function CitasTable({ data, pageSize, selectionModel, setPageSize, setSelectionModel }) {

  try {

    // Estructura de columnas de la tabla
    const columns = [
      { headerName: Constants.LABEL_AUTHORIZATION_NUMBER, field: Constants.TAG_AUTHORIZATION_NUMBER, width: 120 },
      { headerName: Constants.LABEL_PATIENT_NAME, field: Constants.TAG_PATIENT_NAME, width: 150, headerAlign: "center" },
      { headerName: Constants.LABEL_DOCUMENT_TYPE, field: Constants.TAG_DOCUMENT_TYPE, width: 200, headerAlign: "center" },
      { headerName: Constants.LABEL_DOCUMENT_NUMBER_SHORT, field: Constants.TAG_DOCUMENT_NUMBER, width: 150, headerAlign: "center" },
      { headerName: Constants.LABEL_MEDICAL_PLACE_ADDRESS, field: Constants.TAG_MEDICAL_PLACE_ADDRESS, width: 160, headerAlign: "center" },
      { headerName: Constants.LABEL_MEDICAL_PLACE_NAME, field: Constants.TAG_MEDICAL_PLACE_NAME, width: 300, headerAlign: "center" },
      { headerName: Constants.LABEL_MEDICAL_APPOINTMENT_VALUE, field: Constants.TAG_MEDICAL_APPOINTMENT_VALUE, width: 100, headerAlign: "center" },
      { headerName: Constants.LABEL_MEDICAL_APPOINTMENT_DATE, field: Constants.TAG_MEDICAL_APPOINTMENT_DATE, width: 190, headerAlign: "center" },
      { headerName: Constants.LABEL_PATIENT_PHONE, field: Constants.TAG_PATIENT_PHONE, width: 150, headerAlign: "center" }
    ];

    /* INICIO DEL RETURN */
    return (
      <>
        <Grid 
          alignItems="center"
          container
          direction="row" 
          item 
          justifyContent="flex-end" 
          sm={12} 
        >
          <div style={{ height: 435, width: "100%" }}>
            <DataGrid
              checkboxSelection
              columns={columns}
              components={{ Toolbar: GridToolbar }}
              density="compact"
              disableRowSelectionOnClick
              getRowId={(data) => data.autorizacion}
              localeText={esES.components.MuiDataGrid.defaultProps.localeText}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              onRowSelectionModelChange={(id) => {
                setSelectionModel(id);
              }}
              pageSize={pageSize}
              rows={data}
              rowSelectionModel={selectionModel}
              selectionModel={selectionModel}
            />
          </div>
        </Grid>
      </>
    );
  } catch (error) {
    return(
      <>{error}</>
    );
  }
}
