import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, CircularProgress, Dialog, Button } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { today } from "../utils/funciones";
import {
	consultaAdmision,
	consulta_tipo_origen,
	consulta_tipo_solicitud,
	consulta_tipo_ubicacion_paciente,
	consulta_cies,
	// consulta_cups,
	insertarTramite,
} from "../utils/fecth";
import { CupsInstancia } from "../helpers/quemados";
import "../styles/estancias.css";

export default function Estancias() {
	const initial = {
		codigo: "",
		descripcion: "",
	};

	const { nombreParametro } = useParams();
	const [openLoading, setOpenLoading] = useState(false);
	const [admisiones, setAdmisiones] = useState([]);

	const [origenes, setOrigenes] = useState(undefined);
	const [solicitudes, setSolicitudes] = useState(undefined);
	const [ubicaciones, setUbicaciones] = useState(undefined);

	const [cies, setCies] = useState({});
	const [cie, setCie] = useState(initial);
	const [cups, setCups] = useState({});
	const [cup, setCup] = useState(initial);

	const [paciente, setPaciente] = useState({ nombre_paciente: "" });
	const [origen, setOrigen] = useState({});
	const [ubicacion, setUbicacion] = useState({});
	const [solicitud, setSolicitud] = useState({});
	// const [habitacion, setHabitacion] = useState([]);
	// const [dia, setDia] = useState([]);
	const [observacion, setObservacion] = useState("");
	const [date0, setDate0] = useState(today);

	const [correo, setCorreo] = useState("");

	const [celular, setCelular] = useState(undefined);

	const loadDocument = async () => {
		const eps = 900156264;
		setOpenLoading(true);
		const [admision, origenAux, solicitud, ubicacion, cies] = await Promise.all([
			consultaAdmision(),
			consulta_tipo_origen(),
			consulta_tipo_solicitud(),
			consulta_tipo_ubicacion_paciente(),
			consulta_cies(),
			// consulta_cups(),
		]);

		var result = admision.filter((item) => item.id === Number(nombreParametro));
		if (result.length === 0) {
			result = [{ eps: eps, nombre_paciente: "" }];
		}
		setPaciente(result[0]);
		setAdmisiones(admision);
		var origenesEPSaux = origenAux.filter((item) => Number(item.numeroNIT) === eps);
		setOrigenes(origenesEPSaux);
		setSolicitudes(solicitud);
		setUbicaciones(ubicacion);
		setCies(cies);
		setCups(CupsInstancia);
		setOpenLoading(false);
	};

	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleGuardar = async () => {
		setOpenLoading(true);
		const formu = {
			idAdmisiones: paciente.id,
			fechaServicio: date0,
			origen: origen.origen,
			ubicacionPaciente: ubicacion.ubicacionPaciente,
			tipoSolicitud: solicitud.tipoSolicitud,
			guia: null,
			habitacion: "habitacion",
			dias: 1,
			diagnostico: cie.codigo,
			descripcionD: cie.descripcion,
			cups: cup.codigo,
			descripcionC: cup.descripcion,
			observacion: observacion,
			tipoTramite: 2,
			usuarioIPS: "CRISTIAN R",
			correo: correo,
			celular: celular,
		};
		const a = await insertarTramite(formu);
		console.log(a);
		setOpenLoading(false);
		// setFormulario(formu);
	};

	return (
		<div className="contact">
			<div className="leftSide">
				<label>Estancias Hospitalarias</label>
				<div>
					<label> Paciente: </label>
					<Autocomplete
						className="autocompleteCero"
						options={admisiones}
						getOptionLabel={(option) => option?.nombre_paciente}
						renderInput={(params) => <TextField {...params} label="" />}
						value={paciente}
						onChange={(event, newValue) => setPaciente(newValue)}
					/>
				</div>
				<form id="contact-form" method="POST">
					<div>
						<input
							label="Fecha Ingreso"
							type="date"
							value={date0}
							min="2022-10-04"
							max="2022-10-05"
							onChange={(newValue) => {
								setDate0(newValue.target.value);
							}}
							defaultValue={date0}
						/>
						<Autocomplete
							className="autocompleteDos"
							options={origenes}
							getOptionLabel={(option) => option?.origen}
							renderInput={(params) => <TextField {...params} label="Origen" />}
							onChange={(event, newValue) => setOrigen(newValue)}
						/>
					</div>
					<div>
						<Autocomplete
							className="autocompleteUbicacion"
							options={ubicaciones}
							getOptionLabel={(option) => option?.ubicacionPaciente}
							renderInput={(params) => <TextField {...params} label="Ubicacion Paciente" />}
							onChange={(event, newValue) => setUbicacion(newValue)}
						/>
						<Autocomplete
							className="autocompleteSolicitud"
							options={solicitudes}
							getOptionLabel={(option) => option?.tipoSolicitud}
							renderInput={(params) => <TextField {...params} label="Tipo Solicitud" />}
							onChange={(event, newValue) => setSolicitud(newValue)}
						/>
					</div>
					<div className="habitacion">
						{/* <div className="habitacionC">
							<label htmlFor="message">Habitacion</label>
							<TextField
								label="A301"
								onChange={(event) => setHabitacion(event.target.value)}
								value={habitacion}
								required
							/>
						</div>
						<div className="habitacionC">
							<label htmlFor="message">Dias</label>
							<TextField
								label="Cantidad"
								onChange={(event) => setDia(event.target.value)}
								value={dia}
								required
								type="number"
							/>
						</div> */}
					</div>
				</form>
			</div>
			<div className="rightSide">
				<form method="POST">
					<label htmlFor="diagnostico">Dianostico</label>
					<div>
						<Autocomplete
							id="codigoCies"
							options={cies}
							className="autocomplete"
							getOptionLabel={(option) => option?.codigo}
							renderInput={(params) => <TextField {...params} label="CIE10" />}
							value={cie}
							// inputValue={cup?.codigo}
							onChange={(event, newValue) => setCie(newValue)}
						/>
						<Autocomplete
							id="descripcionCies"
							options={cies}
							className="autocompleteDos"
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Descripcion" />}
							value={cie}
							// inputValue={cup?.descripcion}
							onChange={(event, newValue) => setCie(newValue)}
						/>
					</div>
					<label htmlFor="servicio">Servicio</label>
					<div>
						<Autocomplete
							id="codigoCUPS"
							options={cups}
							className="autocomplete"
							getOptionLabel={(option) => option?.codigo}
							renderInput={(params) => <TextField {...params} label="CUPS" />}
							value={cup}
							// inputValue={cup?.codigo}
							onChange={(event, newValue) => setCup(newValue)}
						/>
						<Autocomplete
							id="descripcionCups"
							options={cups}
							className="autocompleteDos"
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Descripcion" />}
							value={cup}
							// inputValue={cup?.descripcion}
							onChange={(event, newValue) => setCup(newValue)}
						/>
					</div>

					<label htmlFor="message">Observaciones</label>
					<TextField
						onChange={(event) => setObservacion(event.target.value)}
						value={observacion}
						className="observacion"
						required
					/>
										<div>
						<TextField
							className="numeroAdmision"
							label="celular"
							variant="filled"
							name="celular"
							onChange={(event) => setCelular(event.target.value)}
							type="text"
							value={celular}
							required
						/>
						<TextField
							className="numDoc"
							label="Corrreo electronico"
							variant="filled"
							name="correo"
							onChange={(event) => setCorreo(event.target.value)}
							type="text"
							value={correo}
							required
						/>
					</div>
					<div className="buttons">
						<Button variant="contained" onClick={() => handleGuardar()}>
							Guardar Estancia
						</Button>
					</div>
				</form>
			</div>
			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
}
