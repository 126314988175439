import "../../styles/Prefactura.css";
import * as Constants from "../../utils/Constants";
import { ModalPrefactura } from "./ModalPrefactura";
import { Button, CircularProgress, Dialog, Grid } from "@material-ui/core";
import { useState, useEffect } from "react";
//import { DataTable } from "./data-table";
import PrefacturaTable from "./Tabla";
//import { Payment, columns } from "./columns";
import {
  listar_prefactura
} from "../../utils/fecth";

// async function getData(): Promise<Payment[]> {
//   return [
//     {
//       id: "728ed52f",
//       amount: 100,
//       status: "pending",
//       email: "m@example.com",
//     },
//   ]
// }

export default function Prefactura() {
  const [openModal, setOpenModal] = useState(false);
  const [openLoading, setOpenLoading] = useState(false);
  const [prefacturas, setPrefacturas] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  //const [cups, setCups] = useState({});
  const [selectionModel, setSelectionModel] = useState([]);
  //const [tipoDoc, setTipoDoc] = useState([]);
  const handleOpen = () => {
    setOpenModal(!openModal);
  };
  const loadPrefacturas = async () => {
    setOpenLoading(true);
    try {
      const data = await listar_prefactura();
      if (data.length > 0) {
        setPrefacturas(data);
      } else {
        setPrefacturas([]);
      }
      // const [cups] = await Promise.all([consulta_cups()]);
      // const tipoDoc = await consulta_tipo_documento();
      // setTipoDoc(tipoDoc);
      // setCups(cups);
    } catch (e) {
      setPrefacturas([]);
    }
    setOpenLoading(false);
  };
  useEffect(() => {
    loadPrefacturas();
  }, []);

  try {
    //const data = await getData()
    return (
      <div className="container">
        <div className="searchContainer">
          {/* <div className="container mx-auto py-10">
            <DataTable columns={columns} data={data} />
          </div> */}
          <div>
            <Grid container justifyContent="flex-end">
              <Button variant="contained" color="primary" onClick={handleOpen}>
                {Constants.LABEL_BUTTON_CREATE_PREFACTURE}
              </Button>
            </Grid>
            <br />
            {prefacturas.length > 0 &&
              <PrefacturaTable
                data={prefacturas}
                pageSize={pageSize}
                selectionModel={selectionModel}
                setPageSize={setPageSize}
                setSelectionModel={setSelectionModel}
              />
            }
          </div>
          {openModal && (
            <ModalPrefactura open={openModal} handleClose={handleOpen} setPrefacturas={setPrefacturas} />
          )}
        </div>
        <Dialog
          open={openLoading}
          PaperProps={{
            style: {
              backgroundColor: "transparent",
              boxShadow: "none",
              width: 100,
              height: 100,
            },
          }}
        >
          <CircularProgress color="inherit" />
        </Dialog>
      </div>
    );
  }
  catch (error) {
    console.error(error)
    return (
      <>Ha sucedido un error</>
    );
  }
}