import "../styles/Footer.css";

function Footer() {
	return (
		<footer className="footer">
			<p> &copy; Ghost copyright Cumplimos.com</p>
		</footer>
	);
}

export default Footer;
