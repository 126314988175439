import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
// import { rows } from "../helpers/MenuList";

import { Ordenes } from "../components/ModalOrdenes";
import { Estancias } from "../components/ModalEstancias";
import { Paciente } from "../components/ModalPaciente";
import { consultar_mipres, descargar_s3 } from "../utils/fecth";
// import { Link } from "react-router-dom";
import { CircularProgress, Dialog, Button } from "@material-ui/core";
import "../styles/pacientes.css";

export default function TablaMipres() {
	const [openLoading, setOpenLoading] = useState(false);
	const [admisiones, setAdmisiones] = useState(undefined);

	const loadDocument = async () => {
		setOpenLoading(true);
		const admisiones = await consultar_mipres();
		setAdmisiones(admisiones);
		setOpenLoading(false);
	};

	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{ field: "admision", headerName: "Admision", width: 140 },
		{
			field: "tipo_documento",
			headerName: "Tipo doc",
			width: 170,
		},

		{
			field: "numero_documento",
			headerName: "No. identificacion",
			width: 190,
		},
		{
			field: "diagnostico1",
			headerName: "Diagnostico",
			width: 155,
		},
		{
			field: "dci",
			headerName: "DCI",
			width: 140,
		},
		{
			field: "numero_solicitud",
			headerName: "Numero de prescripción",
			width: 240,
			renderCell: (cellValues) => {
				const onClick = (e) => {
					console.log(cellValues);
					let archivo = { rutaS3: cellValues.row.ruta };
					descargar_s3(archivo).then((value) => {
						window.open(value, "BDUA", "width=1000,height=1000,scrollbars=NO");
					});
				};

				return (
					cellValues.row.numero_solicitud && (
						<Button onClick={onClick}>{cellValues.row.numero_solicitud} </Button>
					)
				);
			},
		},
		{
			field: "estado",
			headerName: "Estado",
			width: 200,
		},
	];

	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [openPacientes, setOpenPacientes] = useState(false);
	const [data, setData] = useState(undefined);

	const handleClick = (event, cellValues) => {
		setData(!open ? cellValues?.row : undefined);
		setOpen(!open);
	};
	const handleClick2 = (event, cellValues) => {
		setData(!open2 ? cellValues?.row : undefined);
		setOpen2(!open2);
	};
	const handleOpen = () => {
		setOpenPacientes(!openPacientes);
	};

	return (
		<div className="pacientes">
			<div className="tablaPacientes">
				{admisiones && (
					<DataGrid
						rows={admisiones}
						columns={columns}
						pageSize={5}
						components={{
							Toolbar: GridToolbar,
						}}
					/>
				)}
				{open && <Ordenes open={open} handleClose={handleClick} data={data} />}
				{open2 && <Estancias open={open2} handleClose={handleClick2} data={data} />}
				{openPacientes && (
					<Paciente open={openPacientes} handleClose={handleOpen} setAdmisiones={setAdmisiones} />
				)}
			</div>
			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
}
