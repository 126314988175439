import React, { useState } from "react";
import Logo from "../assets/logo.png";
import { Link } from "react-router-dom";
import ReorderIcon from "@material-ui/icons/Reorder";
import "../styles/Navbar.css";
import * as Constants from "../utils/Constants";

export default function Navbar() {
	const [openLinks, setOpenLinks] = useState(false);

	const toggleNavbar = () => {
		setOpenLinks(!openLinks);
	};
	return (
		<div className="navbar">
			<div className="leftSide" id={openLinks ? "open" : "close"}>
				<img src={Logo} alt="logo" />
				<div className="hiddenLinks">
					<Link to="/"> Inicio </Link>
					<Link to="/pacientes"> Pacientes </Link>
					<Link to={Constants.ROUTE_PREFACTURE}> {Constants.LABEL_PREFACTURE} </Link>
					<Link to="/ordenes-medicas"> Ordenes Medicas </Link>
					<Link to="/estancias-hospitalarias"> Estancias Hospitalarias </Link>
					<Link to="/MIPRES"> MIPRES </Link>
					<Link to="/tablaMipres"> Tabla MIPRES </Link>
					<Link to="/ambulatorio"> Pre-Autorizaciones </Link>
					<Link to={Constants.ROUTE_MEDICAL_APPOINTMENT}> {Constants.LABEL_MEDICAL_APPOINTMENT} </Link>
					{/* <Link to="/pruebas"> pruebas </Link> */}
				</div>
			</div>
			<div className="rightSid">
				<Link to="/"> Inicio </Link>
				<Link to="/pacientes"> Pacientes </Link>
				<Link to={Constants.ROUTE_PREFACTURE}> {Constants.LABEL_PREFACTURE} </Link>
				<Link to="/ordenes-medicas"> Ordenes Medicas </Link>
				<Link to="/estancias-hospitalarias"> Estancias Hospitalarias </Link>
				<Link to="/MIPRES"> MIPRES </Link>
				<Link to="/tablaMipres"> Tabla MIPRES </Link>
				<Link to="/ambulatorio"> Pre-Autorizaciones </Link>
				<Link to={Constants.ROUTE_MEDICAL_APPOINTMENT}> {Constants.LABEL_MEDICAL_APPOINTMENT} </Link>

				{/* <Link to="/pruebas"> pruebas </Link> */}
				<button onClick={toggleNavbar}>
					<ReorderIcon />
				</button>
			</div>
		</div>
	);
}
