import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { TextField, CircularProgress, Dialog, Button } from "@material-ui/core";
import Autocomplete from "@mui/material/Autocomplete";
import { today } from "../utils/funciones";
import {
	consultaAdmision,
	consulta_tipo_origen,
	consulta_tipo_solicitud,
	consulta_tipo_ubicacion_paciente,
	consulta_cies,
	consulta_cups,
	insertarTramite,
} from "../utils/fecth";
import "../styles/ordenes.css";
import { getS3PresignedURL, postMedicalAppointmentMessage } from "../utils/CitasServices";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import * as Constants from "../utils/Constants";

export default function OrdenesM() {
	const initial = {
		codigo: "",
		descripcion: "",
	};

	const { nombreParametro } = useParams();
	const [openLoading, setOpenLoading] = useState(false);
	const [admisiones, setAdmisiones] = useState([]);

	// const [origenes, setOrigenes] = useState(undefined);
	const [ubicaciones, setUbicaciones] = useState(undefined);
	const [solicitudes, setSolicitudes] = useState(undefined);

	const [origenesEPS, setOrigenesEPS] = useState(undefined);

	const [cies, setCies] = useState({});
	const [cie, setCie] = useState(initial);
	const [cups, setCups] = useState({});
	const [cup, setCup] = useState(initial);

	const [paciente, setPaciente] = useState({ nombre_paciente: "" });
	const [origen, setOrigen] = useState({});
	const [ubicacion, setUbicacion] = useState({});
	const [solicitud, setSolicitud] = useState({});
	const [guia, setGuia] = useState([]);
	const [observacion, setObservacion] = useState("");
	const [date0, setDate0] = useState(today);

	const [correo, setCorreo] = useState("");

	const [celular, setCelular] = useState(undefined);

	const loadDocument = async () => {
		const eps = 900156264;
		setOpenLoading(true);
		const [admision, origenAux, solicitud, ubicacion, cies, cups] = await Promise.all([
			consultaAdmision(),
			consulta_tipo_origen(),
			consulta_tipo_solicitud(),
			consulta_tipo_ubicacion_paciente(),
			consulta_cies(),
			consulta_cups(),
		]);

		var result = admision.filter((item) => item.id === Number(nombreParametro));
		if (result.length === 0) {
			result = [{ eps: eps, nombre_paciente: "" }];
		}
		setPaciente(result[0]);

		setAdmisiones(admision);
		// setOrigenes(origenAux);

		var origenesEPSaux = origenAux.filter((item) => Number(item.numeroNIT) === eps);

		setOrigenesEPS(origenesEPSaux);

		setSolicitudes(solicitud);
		setUbicaciones(ubicacion);
		setCies(cies);
		setCups(cups);

		setOpenLoading(false);
	};
	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const guias = [
		{
			"guia": "Si",
		},
		{
			"guia": "No",
		},
	];

	const handleGuardar = async () => {
		setOpenLoading(true);
		if (celular !== "" && celular !== undefined && correo !== "" && correo !== undefined) {
			getScreenshot();
			const formu = {
				idAdmisiones: paciente.id,
				fechaServicio: date0,
				origen: origen.origen,
				ubicacionPaciente: ubicacion.ubicacionPaciente,
				tipoSolicitud: solicitud.tipoSolicitud,
				guia: guia.guia,
				habitacion: null,
				dias: 1,
				diagnostico: cie.codigo,
				descripcionD: cie.descripcion,
				cups: cup.codigo,
				descripcionC: cup.descripcion,
				observacion: observacion,
				tipoTramite: 1,
				usuarioIPS: "CRISTIAN R",
				correo: correo,
				celular: celular,
			};
			const a = await insertarTramite(formu);
			console.log(a);
		} else {
			console.log("Debe llenar los campos")
		}

		setOpenLoading(false);
	};

	const getScreenshot = async () => {
		const node = document.getElementById("ordenMedica");
		const doc = new jsPDF({
			orientation: "landscape",
			unit: "in",
			format: [14, 10]
		});	
		try {
			const dataUrl = await htmlToImage.toPng(node);
			const img = new Image();
			const fileName = paciente.nombre_paciente.replace(/ /g, "_")+"_"+date0
			img.src = dataUrl;			
			img.onload = async function() {
				const imgWidth = doc.internal.pageSize.getWidth();
				const imgHeight = (imgWidth * img.height) / img.width;	
				doc.addImage(img, "PNG", 0.6, 0.6, imgWidth, imgHeight);
				const pdfData = doc.output("blob");	
				const pdfFile = new File([pdfData], fileName+".pdf", { type: "application/pdf" });	
				try {
					const responsePath = await getS3PresignedURL(fileName, pdfFile);
					if (responsePath !== undefined) {
						const body = {
							autorizacionNumero: ["00003"],
							tipoMensaje: "2",
							rutaDocumento: responsePath,
							celular: celular
						}
						const response = await postMedicalAppointmentMessage(body);
						if (response !== undefined && response.message !== undefined) {
							if (response.message === Constants.SERVICE_MESSAGE_SUCCESS_2) console.log(response);
							else console.log(response); // notify(Constants.ALERT_INFO_EMPTY_DATA);
						} else {
							console.log(Constants.ALERT_ERROR);
							// notifyError(Constants.ALERT_ERROR);
						}
					} else {
						console.log(Constants.ALERT_ERROR);
						// notifyError(Constants.ALERT_ERROR);
					}
				} catch (error) {
					console.log(Constants.ALERT_ERROR, error);
					// notifyError(Constants.ALERT_ERROR);
				}
				console.log(Constants.SERVICE_MESSAGE_SUCCESS_2);
			};
		} catch (error) {
			console.log(Constants.ALERT_ERROR, error);
			// notifyError(Constants.ALERT_ERROR);
		}
	};

	return (
		<div className="contact" id="ordenMedica">
			<div className="leftSide">
				<label> Ordenes Medicas</label>
				<div>
					<label> Paciente: </label>
					<Autocomplete
						className="autocompleteCero"
						name="paciente"
						options={admisiones}
						getOptionLabel={(option) => option?.nombre_paciente}
						renderInput={(params) => <TextField {...params} label="" />}
						value={paciente}
						onChange={(event, newValue) => setPaciente(newValue)}
					/>
				</div>

				<form id="contact-form" method="POST">
					<div>
						<TextField
							label="Fecha Servicio"
							type="date"
							value={date0}
							onChange={(newValue) => {
								setDate0(newValue.target.value);
							}}
							defaultValue={date0}
						/>
						<Autocomplete
							className="autocompleteDos"
							options={origenesEPS}
							getOptionLabel={(option) => option?.origen}
							renderInput={(params) => <TextField {...params} label="Origen" />}
							onChange={(event, newValue) => setOrigen(newValue)}
						/>
					</div>
					<div>
						<Autocomplete
							className="autocompleteUbicacion"
							options={ubicaciones}
							getOptionLabel={(option) => option?.ubicacionPaciente}
							renderInput={(params) => <TextField {...params} label="Ubicacion Paciente" />}
							onChange={(event, newValue) => setUbicacion(newValue)}
						/>
						<Autocomplete
							className="autocompleteSolicitud"
							options={solicitudes}
							getOptionLabel={(option) => option?.tipoSolicitud}
							renderInput={(params) => <TextField {...params} label="Tipo Solicitud" />}
							onChange={(event, newValue) => setSolicitud(newValue)}
						/>
					</div>
					<div>
						<Autocomplete
							className="autocompleteDos"
							options={guias}
							getOptionLabel={(option) => option?.guia}
							renderInput={(params) => <TextField {...params} label="Manejo Integral Segun Guia" />}
							onChange={(event, newValue) => setGuia(newValue)}
						/>
					</div>
				</form>
			</div>

			<div className="rightSide">
				<form id="contact-form" method="POST">
					<label htmlFor="message">Diagnostico</label>
					<div>
						<Autocomplete
							id="codigoCies"
							options={cies}
							className="autocomplete"
							getOptionLabel={(option) => option?.codigo}
							renderInput={(params) => <TextField {...params} label="CIE10" />}
							value={cie}
							onChange={(event, newValue) => setCie(newValue)}
						/>
						<Autocomplete
							id="descripcionCies"
							options={cies}
							className="autocompleteDos"
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Descripcion" />}
							value={cie}
							onChange={(event, newValue) => setCie(newValue)}
						/>
					</div>
					<label htmlFor="message">Servicio</label>
					<div>
						<Autocomplete
							id="codigoCUPS"
							options={cups}
							className="autocomplete"
							getOptionLabel={(option) => option?.codigo}
							renderInput={(params) => <TextField {...params} label="CUPS" />}
							value={cup}
							onChange={(event, newValue) => setCup(newValue)}
						/>
						<Autocomplete
							id="descripcionCups"
							options={cups}
							className="autocompleteDos"
							getOptionLabel={(option) => option?.descripcion}
							renderInput={(params) => <TextField {...params} label="Descripcion" />}
							value={cup}
							onChange={(event, newValue) => setCup(newValue)}
						/>
					</div>
					<label htmlFor="message">Observaciones</label>
					<TextField
						onChange={(event) => setObservacion(event.target.value)}
						value={observacion}
						className="observacion"
						required
					/>

					<div>
						<TextField
							className="numeroAdmision"
							label="celular"
							variant="filled"
							name="celular"
							onChange={(event) => setCelular(event.target.value)}
							type="text"
							value={celular}
							required
						/>
						<TextField
							className="numDoc"
							label="Corrreo electronico"
							variant="filled"
							name="correo"
							onChange={(event) => setCorreo(event.target.value)}
							type="text"
							value={correo}
							required
						/>
					</div>
					<div className="buttons">
						<Button variant="contained" onClick={() => handleGuardar()}>
							Ejecutar Orden
						</Button>
					</div>
				</form>
			</div>

			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
}
