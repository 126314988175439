import * as React from "react";

import { Backdrop, Modal, Typography, Button, Box, Fade,Tooltip } from "@mui/material";
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DataGrid } from "@material-ui/data-grid";
import { Link } from "react-router-dom";
import { descargar_archivos } from "../utils/fecth";
import "../styles/modalOrdenes.css";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	// height: 500,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export const Estancias = (props) => {
	const { open, handleClose, data } = props;
	const rows = data.estancias;

	const columns = [
		{ field: "fecha_servicio", headerName: "fecha ingreso", width: 200 },
		{ field: "dias", headerName: "dias" },
		{ field: "diagnostico", headerName: "diagnostico", width: 120 },

		{
			field: "cups",
			headerName: "cups",
			width: 120,
			renderCell: (cellValues) => {
				return (
					<div class="tooltip">
						{cellValues.row.cups}
						<span class="tooltiptext">
							&nbsp;&nbsp; {cellValues.row.descripcion_c} &nbsp;&nbsp;
						</span>
					</div>
				);
			},
		},

		{ field: "origen", headerName: "origen", width: 300 },
		{ field: "ubicacion_paciente", headerName: "ubicacion", width: 300 },
		{ field: "tipo_solicitud", headerName: "tipo solicitud", width: 300 },
		{ field: "habitacion", headerName: "habitacion" },
				{
			field: "numeroAutorizacion",
			headerName: "Autorizacion",
			width: 180,
			renderCell: (cellValues) => {
				
				return (<>{cellValues.row.numeroAutorizacion} 
				         {cellValues.row?.docAutorizacion !== null ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    descargar_archivos({rutaS3:cellValues.row?.docAutorizacion}).then(value => {
                      window.open(value, '_parent');
                    })
                  }>
                  <GetAppIcon />
                </IconButton>
              ) : null}</>);
			},
		},
		{
			field: "estado",
			headerName: "estado transaccion",
			width: 200,
			renderCell: (cellValues) => {
				
				return (<><Tooltip  title={ cellValues.row?.msm_fallo == null ? "" : cellValues.row?.msm_fallo}><span> {cellValues.row.estado}
				         {cellValues.row?.doc_fallo !== null ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    descargar_archivos({rutaS3:cellValues.row?.doc_fallo}).then(value => {
                      window.open(value, '_parent');
                    })
                  }>
                  <GetAppIcon />
                </IconButton>
              ) : null}
							</span></Tooltip>
							</>);
			},
		},
	];

	return (
		<div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<Typography id="transition-modal-title" variant="h6" component="h2">
							Estancias medicas
						</Typography>

						{rows && (
							<div className="datagrid">
								<DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
							</div>
						)}
						{!rows && (
							<Typography id="transition-modal-description" sx={{ mt: 2 }}>
								SIN ESTANCIAS
							</Typography>
						)}
						<div className="botones">
							<Button variant="contained" onClick={handleClose}>
								Cancelar
							</Button>
							<Link to={`/ordenes-medicas/${data.id}`}>
								<Button variant="contained"> crear </Button>
							</Link>
						</div>
					</Box>
				</Fade>
			</Modal>
		</div>
	);
};
