import { useEffect, useState } from "react";
import {
	Button,
	TextField,
	Typography,
	Box,
	Modal,
	Backdrop,
	Fade,
	CircularProgress,
	Dialog,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Snackbar
} from "@material-ui/core";
import { Alert, Autocomplete } from '@mui/material';
import "../styles/modalPaciente.css";
import * as Constants from "../utils/Constants";
// import { TextField } from "@mui/material";

import {
	// tipoDocumento,
	eps,
	insertarAdmision,
	consultaAdmision,
	consulta_tipo_documento,
	insertarPaciente
} from "../utils/fecth";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 800,
	// height: 500,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

export const Paciente = (props) => {
	const { open, handleClose, setAdmisiones } = props;

	const dataInicial = {
		numeroAdmision: "",
		tipoDoc: "",
		numDoc: "",
		pagador: 900156264,
		nombrePaciente: "",
		usuarioIPS: "Cris",
		celular: null,
		correo: "",
		numeroAutorizacion: "",
		tipoAutorizacion: ""
	};
	const tiposAutorizacion = ["Urgencias", "Hospitalario", "Ambulatorio"]
	const [data, setData] = useState(dataInicial);
	const [tipoDoc, setTipoDoc] = useState([]);
	const [tipoDocEPS, setTipoDocEPS] = useState([]);
	const [pagador, setPagador] = useState([]);
	const [openSnackbar, setOpenSnackbar] = useState(false);
	const [severity, setSeverity] = useState("");
	const [alertMessage, setAlertMessage] = useState("");

	const [openLoading, setOpenLoading] = useState(false);
	const loadDocument = async () => {
		setOpenLoading(true);
		const pagador = await eps();
		const tipoDoc = await consulta_tipo_documento();
		const tipoDocEPS = tipoDoc.filter((item) => item.numeroNIT === data.pagador);
		setTipoDoc(tipoDoc);
		setTipoDocEPS(tipoDocEPS);
		setPagador(pagador);
		setOpenLoading(false);
	};
	// console.log(tipoDoc);
	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleInputChange = (event) => {
		setData({
			...data,
			[event.target.name]: event.target.value,
		});
	};

	const handleInputChangeAutoComplete = (event, newValue) => {
		setData({
			...data,
			"pagador": newValue?.numeroNIT,
		});
		var tipoDocEPSaux = tipoDoc.filter((item) => item.numeroNIT === Number(newValue?.numeroNIT));

		if (tipoDocEPSaux.length === 0) {
			tipoDocEPSaux = tipoDoc.filter((item) => item.numeroNIT === 900156264);
		}
		setTipoDocEPS(tipoDocEPSaux);
	};

	const handleCrear = async () => {
		if (data.numeroAutorizacion === "" & data.tipoAutorizacion === "Ambulatorio") {
			setAlertMessage('La autorización es obligatoria para ambulatorios');
			setSeverity('warning');
			setOpenSnackbar(true);
		} else {
			setOpenLoading(true);
			//console.log("body ", data);
			await insertarPaciente(data);
			await insertarAdmision(data);
			const admisiones = await consultaAdmision();
			setAdmisiones(admisiones);
			handleClose();
			setOpenLoading(false);
		}
	};

	return (
		<div>
			<Snackbar
				open={openSnackbar}
				autoHideDuration={2000}
				onClose={() => setOpenSnackbar(false)}
			>
				<Alert severity={severity}>
					{alertMessage}
				</Alert>
			</Snackbar>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
			>
				<Fade in={open}>
					<Box sx={style}>
						<form className="formulario">
							<Typography id="transition-modal-title" variant="h6" component="h2">
								Admisiones
							</Typography>
							<div>
								<FormControl className="tipoDoc">
									<InputLabel>{Constants.LABEL_AUTHORIZATION_TYPE}</InputLabel>
									<Select name="tipoAutorizacion" value={data.tipoAutorizacion} onChange={handleInputChange} required>
										{tiposAutorizacion.map((tipo) => (
											<MenuItem value={tipo}>{tipo}</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									className="numDoc"
									label={Constants.LABEL_AUTHORIZATION_NUMBER}
									variant="filled"
									name="numeroAutorizacion"
									onChange={handleInputChange}
									type="text"
									value={data.numeroAutorizacion}
								/>
							</div>
							<div>
								<TextField
									className="numeroAdmision"
									label="Número de Admisión"
									variant="filled"
									name="numeroAdmision"
									onChange={handleInputChange}
									type="text"
									value={data.numeroAdmision}
									required
								/>

								<Autocomplete
									className="pagador"
									name="pagador"
									options={pagador}
									getOptionLabel={(option) => option?.pagador}
									renderInput={(params) => <TextField {...params} label="Pagador" />}
									value={data.numeroNIT}
									onChange={(event, newValue) => handleInputChangeAutoComplete(event, newValue)}
									required={true}
								/>
							</div>
							<div>
								<FormControl className="tipoDoc">
									<InputLabel>Tipo documento</InputLabel>
									<Select name="tipoDoc" value={data.tipoDoc} onChange={handleInputChange}>
										{tipoDocEPS.map((doc) => (
											<MenuItem value={doc.id}>{doc.nombre}</MenuItem>
										))}
									</Select>
								</FormControl>
								<TextField
									className="numDoc"
									label={Constants.LABEL_DOCUMENT_NUMBER}
									variant="filled"
									name="numDoc"
									onChange={handleInputChange}
									type="text"
									value={data.numDoc}
									required
								/>
							</div>
							<div>
								<TextField
									className="nombrePaciente"
									label="Nombre Paciente"
									variant="filled"
									name="nombrePaciente"
									onChange={handleInputChange}
									type="text"
									value={data.nombrePaciente}
									required
								/>
							</div>
							{/* <div>
								<TextField
									className="numeroAdmision"
									label="celular"
									variant="filled"
									name="celular"
									onChange={handleInputChange}
									type="text"
									value={data.celular}
									required
								/>
								<TextField
									className="numDoc"
									label="Corrreo electronico"
									variant="filled"
									name="correo"
									onChange={handleInputChange}
									type="text"
									value={data.correo}
									required
								/>
							</div> */}
							<div className="botones">
								<Button variant="contained" onClick={handleClose}>
									Cancelar
								</Button>
								<Button variant="contained" onClick={handleCrear}>
									Crear
								</Button>
							</div>
						</form>
					</Box>
				</Fade>
			</Modal>
			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
};
