import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
// import { rows } from "../helpers/MenuList";

import { consultaAmbulatorio } from "../utils/fecth";
// import { Link } from "react-router-dom";
import { Button, CircularProgress, Dialog } from "@material-ui/core";
import { ModalAmbulatorio } from "../components/ModalAmbulatorio";
import "../styles/pacientes.css";

export default function PacientesTabla() {
	const [openLoading, setOpenLoading] = useState(false);
	const [ambulatorio, setAmbulatorios] = useState(undefined);

	const loadDocument = async () => {
		setOpenLoading(true);
		const ambulatorio = await consultaAmbulatorio();
		console.log("a", ambulatorio);
		setAmbulatorios(ambulatorio);
		setOpenLoading(false);
	};
	const [open, setOpen] = useState(false);
	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{ field: "numero_admision", headerName: "Admision", width: 140 },
		{
			field: "tipo_documento_identificacion",
			headerName: "Tipo Documento",
			width: 200,
		},

		{
			field: "numero_identificacion_paciente",
			headerName: "N identificacion",
			width: 200,
		},
		{
			field: "nombre_paciente",
			headerName: "Nombre paciente",
			width: 200,
		},

		{
			field: "numero_preautoriozacion",
			headerName: "N pre-autorizacion",
			width: 200,
		},
		{
			field: "estado",
			headerName: "Estado",
			width: 130,
		},
		{
			field: "fecha",
			headerName: "Fecha creacion",
			width: 180,
		},
		{
			field: "afiliacion",
			headerName: "Afiliacion",
			width: 180,
		},
		{
			field: "codigo",
			headerName: "Codigo",
			width: 180,
		},
		{
			field: "descripcion",
			headerName: "Descripcion",
			width: 180,
		},
		{
			field: "cantidad",
			headerName: "Cantidad",
			width: 180,
		},
		{
			field: "anexo",
			headerName: "Anexo",
			width: 180,
		},
		{
			field: "prescripcion",
			headerName: "Prescripcion",
			width: 180,
		},
		{
			field: "consecutivo",
			headerName: "Consecutivo orden",
			width: 180,
		},
	];

	const handleOpen = () => {
		setOpen(!open);
	};

	return (
		<div className="pacientes">
			<Button variant="contained" onClick={handleOpen}>
				Ingreso Ambulatorio
			</Button>
			<div className="tablaPacientes">
				{ambulatorio && (
					<DataGrid
						rows={ambulatorio}
						columns={columns}
						pageSize={5}
						components={{
							Toolbar: GridToolbar,
						}}
					/>
				)}
				{open && (
					<ModalAmbulatorio
						open={open}
						handleClose={handleOpen}
						setAmbulatorios={setAmbulatorios}
					/>
				)}
			</div>
			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
}
