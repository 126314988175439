import * as Constants from "./Constants";
require('dotenv').config();

// const backend = process.env.REACT_APP_BASE_URL_LOCAL;
const backend = process.env.REACT_APP_BASE_URL;

const fetchGETCitas = async (scope) => {
	let response = undefined;
	try {
		const res = await fetch(`${backend}${scope}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json",
				// Authorization: `Bearer ${TokenServis}`,
			},
		});
		const data = await res.json();
		if (data?.msm === Constants.SERVICE_MESSAGE_SUCCESS || data?.message === Constants.SERVICE_MESSAGE_SUCCESS_2) response = data;
		else response = data;
	} catch (err) {
		response = err;
	}
	return response;
}

const fetchPOSTCitas = async (body, scope) => {
	let response = undefined;
	try {
		const res = await fetch(`${backend}${scope}`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				// Authorization: `Bearer ${TokenServis}`,
			},
			body: JSON.stringify(body),
		});

		const data = await res.json();
		if (data?.message === Constants.SERVICE_MESSAGE_SUCCESS_2) response = data;
		else response = data;
	} catch (err) {
		response = err;
	}
	return response;
}

export const getAllDocumentTypes = async () => {
	const scope = Constants.PATH_DOCUMENT_TYPES;
	const response = await fetchGETCitas(scope);
	return response;
}

export const getMedicalAppointmentData = async (body) => {
	const scope = `${Constants.PATH_MEDICAL_APPOINTMENT_LIST}?documentoNumero=${body.documentNumber}&documentoTipo=${body.documentType}`;
	const response = await fetchGETCitas(scope);
	return response;
}

export const postMedicalAppointmentMessage = async (body) => {
	const scope = Constants.PATH_MEDICAL_APPOINTMENT_MSN;
	const response = await fetchPOSTCitas(body, scope);
	return response;
}

export const getS3PresignedURL = async (fileName, file) => {
	let response = undefined;
	try {
		if (fileName !== undefined && fileName !== "") {
			const formData = new FormData();
			const fileNameString = fileName + ".pdf";
			const body = {
				nombreArchivo: fileNameString,
			};
			const scope = Constants.PATH_MEDICAL_APPOINTMENT_URL;
			const dataURL = await fetchPOSTCitas(body, scope);
			if (dataURL?.code === 200 && dataURL?.status) {
				for (const key in dataURL?.url?.fields) {
					formData.append(key, dataURL?.url?.fields[key]);
				}
				formData.append("file", file, fileNameString);
				const response = await fetch(dataURL?.url?.url, {
					method: "POST",
					body: formData,
				});
				if (response.ok) {
					return dataURL?.url?.url;
				}
			}
		}
	} catch (error) {
		console.log(error);
	}
	return response;
};