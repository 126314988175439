import { useState } from "react";
import { Link } from "react-router-dom";
import { CircularProgress, Dialog, Button } from "@material-ui/core";
import { integracionA, integracionD } from "../utils/fecth";
import BannerImage from "../assets/symbol.png";
import "../styles/Home.css";

export default function Home() {
	const [openLoading, setOpenLoading] = useState(false);
	const handleIntegracionA = async () => {
		setOpenLoading(true);
		await integracionA();
		// console.log(a);
		setOpenLoading(false);
	};
	const handleIntegracionD = async () => {
		setOpenLoading(true);
		await integracionD();
		// console.log(a);
		setOpenLoading(false);
	};
	return (
		<div className="home" style={{ backgroundImage: `url(${BannerImage})` }}>
			<div className="headerContainer">
				<h1> Ghost Medi </h1>
				<p> Simulemos un core hospitalario</p>
				<div>
					<Link to="/pacientes">
						<button> PACIENTES </button>
					</Link>
					<Button variant="contained" onClick={handleIntegracionA}>
						validar derechos
					</Button>
					<Button variant="contained" onClick={handleIntegracionD}>
						generar autorizaciones
					</Button>
					<Dialog
						open={openLoading}
						PaperProps={{
							style: {
								backgroundColor: "transparent",
								boxShadow: "none",
								width: 100,
								height: 100,
							},
						}}
					>
						<CircularProgress color="inherit" />
					</Dialog>
				</div>
			</div>
		</div>
	);
}
