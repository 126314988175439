// const backend = process.env.REACT_APP_BASE_URL_LOCAL;
const backend = process.env.REACT_APP_BASE_URL;

const fetchPOST = async (entrada, scope) => {
	try {
		const res = await fetch(`${backend}${scope}`, {
			method: "POST",
			headers: {
				"Content-type": "application/json",
				// Authorization: `Bearer ${TokenServis}`,
			},
			body: JSON.stringify(entrada),
		});

		const data_ = await res.json();
		if (data_.msm === "SERVICE_SUCCESS") {
			return data_?.Respuesta;
		}
	} catch (err) {
		return err;
	}
};

const fetchGET = async (scope) => {
	try {
		const res = await fetch(`${backend}${scope}`, {
			method: "GET",
			headers: {
				"Content-type": "application/json",
				// Authorization: `Bearer ${TokenServis}`,
			},
		});

		const data_ = await res.json();
		if (data_.msm === "SERVICE_SUCCESS") {
			return data_?.Respuesta;
		}
	} catch (err) {
		return err;
	}
};

export const consulta_tipo_documento = async () => {
	const scope = "consultaTipoDocumento";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consulta_tipo_origen = async (eps) => {
	const scope = "consultaTipoOrigenAtencion";

	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consulta_tipo_solicitud = async () => {
	const scope = "consulta_tipo_solicitud";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consulta_tipo_ubicacion_paciente = async () => {
	const scope = "consulta_tipo_ubicacion_paciente";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consulta_cies = async () => {
	const scope = "consulta_cies";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consulta_cups = async () => {
	const scope = "consulta_cups";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const eps = async () => {
	const scope = "consulta_pagador_por_tipo";
	const nitEps = {
		tipoEntidad: 1,
	};
	const respuesta = await fetchPOST(nitEps, scope);
	return respuesta?.pagadores;
};

export const consultaAdmision = async () => {
	const scope = "consultaAdmision";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consultaAmbulatorio = async () => {
	const scope = "consultaAmbulatorio";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const insertarAdmision = async (entrada) => {
	const scope = "insertarAdmision";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const insertarAmbulatorio = async (entrada) => {
	const scope = "insertarAmbulatorio";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const insertarTramite = async (entrada) => {
	const scope = "insertarTramite";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const integracionA = async () => {
	const scope = "lambda_handler";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const integracionD = async () => {
	const scope = "lambda_handler2";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

// MIPRES

export const consultar_cie10 = async () => {
	const scope = "consultar_cie10";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consultar_tipo_documento = async () => {
	const scope = "consultar_tipo_documento";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consultar_ambito_atencion = async () => {
	const scope = "consultar_ambito_atencion";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consultar_tipo_medicamento = async () => {
	const scope = "consultar_tipo_medicamento";
	const respuesta = await fetchGET(scope);
	return respuesta;
};
export const consultar_tipo_dci = async () => {
	const scope = "consultar_tipo_dci";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consultar_concentracion = async (entrada) => {
	const scope = "consultar_concentracion";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};
export const consultar_campos = async (entrada) => {
	const scope = "consultar_campos";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};
export const consultar_indicaciones = async () => {
	const scope = "consultar_indicaciones";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consultar_tipo_frecuencia = async () => {
	const scope = "consultar_tipo_frecuencia";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const insertar_mipres = async (entrada) => {
	const scope = "insertar_mipres";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const consultar_mipres = async () => {
	const scope = "consultar_mipres";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const descargar_s3 = async (entrada) => {
	const scope = "descargar_s3";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const descargar_archivos = async (entrada) => {
	const scope = "descargar_archivos";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

//PREFACTURA

export const crear_prefactura = async (entrada) => {
	const scope = "prefactura/crear";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const listar_prefactura = async () => {
	const scope = "prefactura/listar";
	const respuesta = await fetchGET(scope);
	return respuesta;
};

export const consulta_paciente = async (entrada) => {
	const scope = "paciente/buscar";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};

export const insertarPaciente = async (entrada) => {
	const scope = "paciente/crear";
	const respuesta = await fetchPOST(entrada, scope);
	return respuesta;
};