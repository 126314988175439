export const CupsInstancia = [
	{
		codigo: "105M01",
		descripcion: "INTERNACION EN UNIDAD DE CUIDADO INTERMEDIO NEONATAL",
	},
	{
		codigo: "106M01",
		descripcion: "INTERNACION EN UNIDAD DE CUIDADO INTERMEDIO PEDIATRICO",
	},
	{
		codigo: "107M01",
		descripcion: "INTERNACION EN UNIDAD DE CUIDADO INTERMEDIO ADULTO",
	},
	{
		codigo: "108A01",
		descripcion: "INTERNACION EN UNIDAD DE CUIDADO INTENSIVO NEONATAL",
	},
	{
		codigo: "109A01",
		descripcion: "INTERNACION EN UNIDAD DE CUIDADO INTENSIVO PEDRIATICO",
	},
	{
		codigo: "10A001",
		descripcion: "INTERNACION COMPLEJIDAD ALTA HABITACION UNIPERSONAL (INCLUYE AISLAMIENTO)",
	},
	{
		codigo: "10A002",
		descripcion: "INTERNACION COMPLEJIDAD ALTA HABITACION BIPERSONAL",
	},
	{ codigo: "10A003", descripcion: "INTERNACION COMPLEJIDAD ALTA TRES CAMAS" },
	{
		codigo: "10A004",
		descripcion: "INTERNACION COMPLEJIDAD ALTA CUATRO O MAS CAMAS",
	},
	{ codigo: "10A005", descripcion: "INTERNACION EN UNIDAD DE TRANSPLANTE" },
	{
		codigo: "110A01",
		descripcion: "INTERNACION EN UNIDAD DE CUIDADO INTENSIVO ADULTOS",
	},
	{
		codigo: "120N01",
		descripcion: "INTERNACION COMPLEJIDAD MEDIANA HABITACION TRES CAMAS",
	},
];
