import * as Constants from "../../utils/Constants";
import { Grid } from "@material-ui/core";
import { DataGrid, esES } from "@mui/x-data-grid";

export default function PrefacturaTable({ data, pageSize, selectionModel, setPageSize, setSelectionModel }) {

    // Estructura de columnas de la tabla
    const columns = [
        { headerName: Constants.LABEL_PREFACTURE_NUMBER, field: Constants.TAG_PREFACTURE, width: 85, headerAlign: "center"},
        { headerName: Constants.LABEL_PATIENT_NAME, field: Constants.TAG_PATIENT_NAME, width: 170, headerAlign: "center" },
        { headerName: Constants.LABEL_DOCUMENT_TYPE, field: Constants.TAG_DOCUMENT_TYPE, width: 150, headerAlign: "center" },
        { headerName: Constants.LABEL_DOCUMENT_NUMBER_SHORT, field: Constants.TAG_DOCUMENT_NUMBER, width: 100, headerAlign: "center" },
        { headerName: Constants.LABEL_CUPS, field: Constants.TAG_CUPS, width: 70, headerAlign: "center" },
        { headerName: Constants.LABEL_QUANTITY, field: Constants.TAG_QUANTITY, width: 75, headerAlign: "center" },
        { headerName: Constants.LABEL_DESCRIPTION, field: Constants.TAG_DESCRIPTION, width: 300, headerAlign: "center" },
        { headerName: Constants.LABEL_VALUE, field: Constants.TAG_VALUE, width: 80, headerAlign: "center" },
        { headerName: Constants.LABEL_DATE, field: Constants.TAG_DATE, width: 95, headerAlign: "center" },
        { headerName: Constants.LABEL_PAYER, field: Constants.TAG_EPS, width: 100, headerAlign: "center" },
        { headerName: Constants.LABEL_COVER, field: Constants.TAG_PATIENT, width: 80, headerAlign: "center" }
    ];

    try {
        return (
            <>
                <Grid
                    alignItems="center"
                    container
                    direction="row"
                    item
                    justifyContent="flex-end"
                    sm={12}
                >
                    <div style={{ height: 350, width: "100%" }}>
                        <DataGrid
                            columns={columns}
                            density="compact"
                            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            onRowSelectionModelChange={(id) => {
                                setSelectionModel(id);
                            }}
                            sx={{
                                '& .MuiDataGrid-columnHeaderTitle': {
                                  whiteSpace: 'break-spaces',
                                  lineHeight: 1,
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitleContainer': {
                                  pl: 1,
                                },
                                '&.MuiDataGrid-root .MuiDataGrid-cell': {
                                    whiteSpace: 'normal !important',
                                    wordWrap: 'break-word !important',
                                },
                            }}
                            pageSize={pageSize}
                            rows={data}
                            selectionModel={selectionModel}
                            getRowId={(data) => data.pkPrefactura}
                        />
                    </div>
                </Grid>
            </>
        );
    } catch (error) {
        return (
            <>{error}</>
        );
    }
}