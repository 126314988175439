import { useState, useEffect } from "react";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
// import { esES } from "@material-ui/core/locale";

// import { rows } from "../helpers/MenuList";

import { Ordenes } from "../components/ModalOrdenes";
import { Estancias } from "../components/ModalEstancias";
import { Paciente } from "../components/ModalPaciente";
import { consultaAdmision,descargar_archivos } from "../utils/fecth";
// import { Link } from "react-router-dom";
import { Button, CircularProgress, Dialog,Tooltip } from "@material-ui/core";

import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';
// import { Tooltip } from '@mui/material';

import "../styles/pacientes.css";
// https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/constants/localeTextConstants.ts
const translations = {
	columnMenuLabel: "Menu",
	columnMenuShowColumns: "mostrar columnas",
	columnMenuManageColumns: "manejar columnas",
	columnMenuFilter: "filtrar",
	columnMenuHideColumn: "Esconder columnas",
	columnMenuUnsort: "desordenado",
	columnMenuSortAsc: "ordenar ascendente",
	columnMenuSortDesc: "ordenar descendente",
};
export default function PacientesTabla() {
	const [openLoading, setOpenLoading] = useState(false);
	const [admisiones, setAdmisiones] = useState(undefined);

	const loadDocument = async () => {
		setOpenLoading(true);
		const admisiones = await consultaAdmision();


		setAdmisiones(admisiones);
		setOpenLoading(false);
	};

	useEffect(() => {
		loadDocument();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	

	const columns = [
		{ field: "numero_admision", headerName: "Admision", width: 140,align:'center' },
		{
			field: "nombreTD",
			headerName: "Tipo Documento",
			width: 200,
		},

		{
			field: "numero_identificacion_paciente",
			headerName: "Num Identificacion",
			width: 200,
		},
		{
			field: "nombre_paciente",
			headerName: "Nombre Paciente",
			width: 210,
		},
		{
			field: "pagador",
			headerName: "EPS",
			width: 200,
		},

		{
			field: "estadoEPS",
			headerName: "estado",
			width: 130,
			renderCell: (cellValues) => {
				
				return (<>{cellValues.row.estadoEPS} 
				         {cellValues.row?.archivoValidacion !== null ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    descargar_archivos({rutaS3:cellValues.row?.archivoValidacion}).then(value => {
                      window.open(value, '_parent');
                    })
                  }>
                  <GetAppIcon />
                </IconButton>
              ) : null}</>);
			},
		},
		{
			field: "estado",
			headerName: "estado transaccion",
			width: 200,
			renderCell: (cellValues) => {
				
				return (<><Tooltip  title={ cellValues.row?.msm_fallo == null ? "" : cellValues.row?.msm_fallo}><span> {cellValues.row.estado}
				         {cellValues.row?.doc_fallo !== null ? (
                <IconButton
                  variant="contained"
                  color="primary"
                  onClick={() =>
                    descargar_archivos({rutaS3:cellValues.row?.doc_fallo}).then(value => {
                      window.open(value, '_parent');
                    })
                  }>
                  <GetAppIcon />
                </IconButton>
              ) : null}
							</span></Tooltip>
							</>);
			},
		},
		{
			field: "fecha",
			headerName: "fecha",
			width: 180,
		},
		{
			field: "ordenes",
			headerName: "Ordenes",
			width: 170,
			renderCell: (cellValues) => {
				const onClick = (e) => {
					handleClick(e, cellValues);
				};
				var register = "SIN ORDENES";
				if (cellValues.row.ordenes) {
					register = "CON ORDENES";
				}
				return <Button onClick={onClick}>{register}</Button>;
			},
		},
		{
			field: "estancias",
			headerName: "Estancias",
			width: 170,
			renderCell: (cellValues) => {
				const onClick = (e) => {
					handleClick2(e, cellValues);
				};
				var register = "SIN ESTANCIA";
				if (cellValues.row.estancias) {
					register = "CON ESTANCIA";
				}
				return <Button onClick={onClick}>{register}</Button>;
			},
		},
	];

	const [open, setOpen] = useState(false);
	const [open2, setOpen2] = useState(false);
	const [openPacientes, setOpenPacientes] = useState(false);
	const [data, setData] = useState(undefined);

	const handleClick = (event, cellValues) => {
		setData(!open ? cellValues?.row : undefined);
		setOpen(!open);
	};
	const handleClick2 = (event, cellValues) => {
		setData(!open2 ? cellValues?.row : undefined);
		setOpen2(!open2);
	};
	const handleOpen = () => {
		setOpenPacientes(!openPacientes);
	};

	return (
		<div className="pacientes">
			<Button variant="contained" onClick={handleOpen}>
				Admitir paciente
			</Button>
			<div className="tablaPacientes">
				{admisiones && (
					<DataGrid
						localeText={translations}
						rows={admisiones}
						columns={columns}
						pageSize={5}
						components={{
							Toolbar: GridToolbar,
						}}
					/>
				)}
				{open && <Ordenes open={open} handleClose={handleClick} data={data} />}
				{open2 && <Estancias open={open2} handleClose={handleClick2} data={data} />}
				{openPacientes && (
					<Paciente open={openPacientes} handleClose={handleOpen} setAdmisiones={setAdmisiones} />
				)}
			</div>
			<Dialog
				open={openLoading}
				PaperProps={{
					style: {
						backgroundColor: "transparent",
						boxShadow: "none",
						width: 100,
						height: 100,
					},
				}}
			>
				<CircularProgress color="inherit" />
			</Dialog>
		</div>
	);
}
